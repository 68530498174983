import type { PointOfSale } from "~/models/PointOfSale";

export const useStorageStore = defineStore('storage', {
    state: (): { storage: PointOfSale } => ({
        storage: {
            id: 0,
            name: '',
            description: '',
            address: '',
            crud_permissions: {}
        }
    }),
    getters: {
        getStorage(state) {
            const storeSelected = localStorage.getItem('storeSelected');  
            state.storage = storeSelected ? JSON.parse(storeSelected) : null;
            return state.storage
        }
    },
    actions: {
        setStorage(newStorage: any) {
            localStorage.setItem('storeSelected', JSON.stringify(newStorage));
        },
      },
  })